.container2{
    width: 90%;
    min-width: 300px;
    max-width: 600px;
    position: absolute;
    top: 100vh;
    transform: translateY(-1%);
    padding-top: 14px;
}

.img_copa{
    max-width:300px;
    text-align:center;
}

.table_text{
    text-align:center;
}

a{
    color:white;
}

.h3_intro{
    font-size:1.2em;
    color:#61f2f0 !important;
}
