@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;1,500&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: #7e3e98;
}

code {
  font-family: 'Montserrat', sans-serif, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='radio'] {
  margin: 0 0 0 16px;
  width: 0;
  height: 0;
}

html{
  scroll-behavior:smooth;
}

.container {
  width: 100vw;
  max-width: 500px;
  min-height: 100vh;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

h3 {
  max-width: 90vw;
  text-align: left;
  font-size: 2em;
  margin-left: 16px;
}

h6 {
  margin-top: -14px;
  margin-bottom: 0px;
  margin-left: 16px;
  text-align: center;
  width: 90vw;
  max-width:500px;
  font-size: 1.1em;
  color: #6ee6e5;
}

button {
  border: 0px solid transparent;
  border-radius: 30px;
  background-color:  #22a7a5;
  padding: 10px 16px;
  font-size: 1.5em;
  margin: 1em;
  min-width: 100px;
  font-weight: 600;
  color: #FFF;
  font-family: 'Montserrat',sans-serif;
}

.button-arrow {
  font-size: 1em;
  padding: 1em;
  margin: 0.6em;
  width: 9em;
  font-weight: 700;
  font-family: 'Montserrat',sans-serif;
}

.button-arrow-last {
  background-color: #22a7a5;
  color: white;
  font-size: 1em;
  padding: 1em;
  margin: 0.6em;
  width: 9em;
  font-weight: 700;
}

.asana {
  color: #b144a1;
}

.lista {
  margin: 0;
  margin-bottom: 0.8em;
}

.title {
  line-height: 1.4em;
  margin-bottom: 0.5em;
}

.image {
  width: 200px;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  max-height: 165px;
  margin-top: 10px;
}

.questions {
  width: 85%;
  display: -webkit-flex;
  display: flex;
  margin-top: 1.2em;
  padding: 1em 0px;
  padding-right: 1em;
  border: 1px solid #c384c52e;
  background-color: #c384c56e;
  border-radius: 10px;
  -webkit-align-items: center;
          align-items: center;
  font-size: 13px;
  font-weight: 700;
}

.questionsWithImage {
  width: 85%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  margin-top: 1em;
  padding: 1em 0px;
  padding-right: 1em;
  border: 1px solid #c384c52e;
  background-color: #c384c56e;
  border-radius: 10px;
  -webkit-align-items: center;
          align-items: center;
  font-size: 13px;
}

.questions:first-of-type {
  /* margin-top: 0em; */
}
.questions:last-of-type {
  margin-bottom: 1em;
}

form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  color: white;
  width: 100%;
}

label {
  font-weight: 700;
}

.from-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.disabled {
  background-color: #b144a149;
  color: #642f79;
}

.button-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.labelImage {
  width: 80%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 5px;
}

.labelNoImage {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  margin-left: 1em;
}

.selected {
  background-color: #22a7a5;
}

.input {
  width: 100%;
  margin-top: 1em;
  padding: 12px;
  color: white;
  border: 0px;
  border-bottom: 1px solid white;
  font-family: Montserrat, sans-serif;
  background-color: transparent;
  font-size: 1.3em;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #22a7a5;
  opacity: 1; /* Firefox */
  font-weight: 500;
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #22a7a5;
  opacity: 1; /* Firefox */
  font-weight: 500;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #22a7a5;
  opacity: 1; /* Firefox */
  font-weight: 500;
}
.view-result{
  border: 0px solid transparent;
  background-color: #04b5c7;
  padding: 12px;
  font-size: 1.5em;
  margin: 2em;
  font-weight: 600;
  color: #FFF;
  font-family: 'Montserrat',sans-serif;
  width: 100%;
  border-radius: 34px;
  max-width:252px;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #22a7a5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #22a7a5;
}

.user-info {
  width: 70%;
}

.checkbox-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  width: 100%;
}

.input-checkbox {
  margin: 0;
  padding: 0;
}
.skip-btn{
  text-decoration: underline;
  font-style: normal;
  color: #FFF;
  font-size: 13px;
  text-align: center;
  background-color: transparent;
  margin:0;
}

.view-result{
  border: 0 solid transparent;
  background-color: #04b5c7;
  padding: 12px;
  font-size: 1.5em;
  margin: 2em;
  font-weight: 600;
  color: #fff;
  font-family: "Montserrat",sans-serif;
  width: 100%;
  border-radius: 34px;
  max-width: 252px;
}
.hello {
  font-size: 26px;
}

body {
  font-family: Montserrat,sans-serif,Verdana, Geneva, Tahoma, sans-serif;
  font-size: 12px;
}
.summary_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 0.3em;
  max-width: 50vw;
}
.summary_question {
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.summary_question-bullet {
  width: 10%;
}
.summary_question-question {
  color: #b144a1;
  margin-bottom: 0.1em;
}
.asana {
  color: #b144a1;
}
.answers {
  margin-top: 1em;
  padding: 0.8em;
  border-top: 1px solid #b144a1;
  border-bottom: 1px solid #b144a1;
}

.buy {
width: 220px;
padding: 1em 0px;
-webkit-justify-content: space-between;
        justify-content: space-between;
}

.buyColumn{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items:center;
          align-items:center;
}

.button {
  padding: 0.7em;
  background-color: #fb5234;
  border-radius: 31px;
  color:#f7f7f7;
  text-decoration: none;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 14px;
  font-weight: 700;
}

.buttonPromo {
  padding: 0.5em 1.2em;
  width: 85px;
  margin: 0.5em;
  background-color: #22a7a5;
  border-radius: 5px;
  height: 30px;
  color:#f7f7f7;
  text-decoration: none;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 12px;
  font-weight: 700;
}

.disclaimer {
  font-size: 10px;
  margin-top:6px;
  color: #b144a1;
  font-style: italic;
}
.bold {
  font-style: normal;
  margin-top:0px;
  width:88%;
  border: 1px solid #b144a1;
  padding: .5em;
  font-size:13px;
  text-align:center;
}

.dark{
  font-weight: bold;
  font-size:13px;
}
.underline{
  font-style: normal;
  color:#11ba17;
  font-size:12px;
  text-align:center;
  font-weight: 600;
  padding-left:6px
}

.whatsapp{
display:-webkit-flex;
display:flex;
-webkit-align-items:center;
        align-items:center;
-webkit-justify-content:center;
        justify-content:center;
padding:5px;
}

.recommendation {
  width: 78%;
  margin: 0 auto;
  margin-top: -10px;
  padding: 1em;
  /* border: 2px solid #b144a1; */
  border-radius: 10px;
  background: white;
  max-width: 420px;
}
.product {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.product-title {
  font-size: 22px;
  color: #b144a1;
  font-weight: bold;
  margin: 0.3em;
}

.product-description{
  font-size: 14px;
  margin-top:5px;
  color: #b144a1;
  text-align:center;
  font-weight:600;
  max-width:300px;
}

.product-price1{
  font-size: 15px;
  color: #b144a1;
  font-weight: bold;
}

.product-price2{
  font-size: 15px;
  color: #44afb1;
  font-weight: bold;
}

.light{
  font-weight: 500;
}

.product-image {
  width: 180px;
  height: 180px;
  background-image: url('{{reco.image}}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.black {
  color: inherit;
}

.footer {
  margin-top: 0.5em;
}

@media screen and (min-width: 750px) {
  .buy {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
}

.container2{
    width: 90%;
    min-width: 300px;
    max-width: 600px;
    position: absolute;
    top: 100vh;
    -webkit-transform: translateY(-1%);
            transform: translateY(-1%);
    padding-top: 14px;
}

.img_copa{
    max-width:300px;
    text-align:center;
}

.table_text{
    text-align:center;
}

a{
    color:white;
}

.h3_intro{
    font-size:1.2em;
    color:#61f2f0 !important;
}

