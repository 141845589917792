html{
  scroll-behavior:smooth;
}

.container {
  width: 100vw;
  max-width: 500px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h3 {
  max-width: 90vw;
  text-align: left;
  font-size: 2em;
  margin-left: 16px;
}

h6 {
  margin-top: -14px;
  margin-bottom: 0px;
  margin-left: 16px;
  text-align: center;
  width: 90vw;
  max-width:500px;
  font-size: 1.1em;
  color: #6ee6e5;
}

button {
  border: 0px solid transparent;
  border-radius: 30px;
  background-color:  #22a7a5;
  padding: 10px 16px;
  font-size: 1.5em;
  margin: 1em;
  min-width: 100px;
  font-weight: 600;
  color: #FFF;
  font-family: 'Montserrat',sans-serif;
}

.button-arrow {
  font-size: 1em;
  padding: 1em;
  margin: 0.6em;
  width: 9em;
  font-weight: 700;
  font-family: 'Montserrat',sans-serif;
}

.button-arrow-last {
  background-color: #22a7a5;
  color: white;
  font-size: 1em;
  padding: 1em;
  margin: 0.6em;
  width: 9em;
  font-weight: 700;
}

.asana {
  color: #b144a1;
}

.lista {
  margin: 0;
  margin-bottom: 0.8em;
}

.title {
  line-height: 1.4em;
  margin-bottom: 0.5em;
}
